import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { take, takeUntil, map, filter } from 'rxjs/operators';
import { Facility, TimeClockEntry, TimeClockAction, ePermissions, TimeClockNote, User, DataListItem } from '@app/model';
import { CacheManQuery } from '@app/store';
import { TimeClockEntryService } from '@app/core/services/time-clock-entry.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeClockModalComponent } from './time-clock-modal/time-clock-modal.component';
import { OnDestroyComponent } from '@app/shared';
import * as moment from 'moment';
import { AnalyticsService, ToastMessageService, FacilitiesService, UIPermissionConstants, CacheManService, DataListConstants } from '@app/core';
import { modalLargeOption, modalOptions } from '@app/utils';
import { TimeClock } from './time-clock';

@Component({
    selector: 'tms-time-clock',
    templateUrl: './time-clock.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeClockComponent extends OnDestroyComponent implements OnInit, OnDestroy {
    clockButtonText: string = 'Clock In';
    clockedIn: boolean = false;
    currentlyDriving: boolean = false;

    disciplines$: Observable<DataListItem[]> = null;
    designations$: Observable<DataListItem[]> = null;
    facilities$: Observable<Facility[]>;
    facilities: Facility[];
    originFacility: string;
    transferFacility: string;
    defaultFacility: string;

    recentEntries$: Observable<TimeClockEntry[]>;
    mostRecentEntry: TimeClockEntry;
    timeclockAddPermission: ePermissions;
    timeClockNote: string;
    currentUser: User = null;

    payType: any[] = [{ id: 1, name: 'Normal' }, { id: 2, name: 'Normal' }, { id: 3, name: 'Travel' }];

    modalReferance: any = null;

    constructor(private cacheManService: CacheManService,
        private modalService: NgbModal,
        private facilitiesService: FacilitiesService,
        private timeClockEntryService: TimeClockEntryService,
        private toastMessageService: ToastMessageService,
        private analyticsService: AnalyticsService,
        public activeModal: NgbActiveModal,
        private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.timeclockAddPermission = UIPermissionConstants.TIMECLOCK_ADD;
        this.facilities$ = this.cacheManService.getUserFacilities()
            .pipe(map(facilities => this.facilities = facilities));
        this.cacheManService.getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                if (user) {
                    this.currentUser = user;
                }
            });

        this.disciplines$ = this.cacheManService.getDataListItems$(DataListConstants.DISCIPLINES);
        this.designations$ = this.cacheManService.getDataListItems$(DataListConstants.DESIGNATIONS);
        
        this.getDefaultFacility();
        this.getRecentEntries();
        this.getMostRecentEntry();
        this.getCurrentNote();
    }

    updateNoteButtonClicked(e) {
        let dt = new Date();
        const timeZoneOffset = dt.getTimezoneOffset() / 60; //Offset time in minutes
        dt.setHours(dt.getHours() - timeZoneOffset);
        let note: TimeClockNote = { userId: this.currentUser.id, noteDate: dt };
        note.note = this.timeClockNote;

        this.timeClockEntryService.updateNoteEntry(note)
            .pipe(take(1))
            .subscribe(response => {
                this.toastMessageService.successNotification("Note updated.");
            }, (error) => {
                this.toastMessageService.errorNotification("Error saving note.");
            });
    }

    clockButtonClicked(e) {
        this.modalReferance = this.modalService.open(TimeClockModalComponent, { ...modalOptions, ...modalLargeOption });
        this.modalReferance.componentInstance.facilities = [...this.facilities];
        this.modalReferance.componentInstance.clockedIn = this.clockedIn;
        this.modalReferance.componentInstance.currentlyDriving = this.currentlyDriving;
        this.modalReferance.componentInstance.transferFacility = this.transferFacility;
        this.modalReferance.componentInstance.originFacility = this.originFacility;
        this.modalReferance.componentInstance.defaultFacility = this.defaultFacility;
        this.modalReferance.componentInstance.currentUser = this.currentUser;
        this.modalReferance.componentInstance.timeclockEntryUpdated
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((updated) => {
                if (updated) {
                    this.getDefaultFacility();
                    this.getRecentEntries();
                    this.getMostRecentEntry();
                    this.modalReferance.close();
                    this.cdr.detectChanges();
                } else {
                    this.modalReferance.close();
                    this.toastMessageService.errorNotification('Error updating time clock entries');
                }
            });
    }

    getDefaultFacility() {
        this.facilitiesService.activeFacilityId$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(id => {
                if (id && id !== null && id !== '') {
                    this.defaultFacility = id;
                } else {
                    if (this.currentUser && this.currentUser.employee && this.currentUser.facilityIds && this.currentUser.facilityIds.length > 0) {
                        this.defaultFacility = this.currentUser.facilityIds[0];
                    }
                }
            });
    }

    getRecentEntries() {
        this.recentEntries$ = this.timeClockEntryService.getRecentEntries()
            .pipe(map(entries => {
                return TimeClock.FilterEntriesByToday(TimeClock.FilterUnapprovedPtoEntries(entries))
            }), take(1))
    }

    getMostRecentEntry() {
        this.timeClockEntryService.getMostRecentEntry()
            .pipe(take(1))
            .subscribe(entry => {
                this.mostRecentEntry = entry;

                // Check if most recent entry is from before today
                if (this.mostRecentEntry && moment(this.mostRecentEntry.clockIn).isBefore(moment(), 'day')) {
                    this.mostRecentEntry = null;
                }

                if (this.mostRecentEntry) {
                    if (this.mostRecentEntry.timeClockAction === TimeClockAction.Driving) {
                        if (!this.mostRecentEntry.clockOut || this.mostRecentEntry.clockOut === null) {
                            this.clockedIn = false;
                            this.currentlyDriving = true;
                            this.clockButtonText = 'Clock In';
                            this.transferFacility = this.mostRecentEntry.facilityId;
                        } else {
                            this.clockedIn = false;
                            this.currentlyDriving = false;
                            this.originFacility = this.mostRecentEntry.facilityId;
                            this.clockButtonText = 'Clock In';
                        }
                    } else {
                        if (!this.mostRecentEntry.clockOut || this.mostRecentEntry.clockOut === null) {
                            this.clockedIn = true;
                            this.currentlyDriving = false;
                            this.originFacility = this.mostRecentEntry.facilityId;
                            this.clockButtonText = 'Clock Out';
                        } else {
                            this.clockedIn = false;
                            this.currentlyDriving = false;
                            this.clockButtonText = 'Clock In';
                        }
                    }
                }

                this.cdr.detectChanges();
            });
    }

    getCurrentNote() {
        this.timeClockEntryService.getCurrentNoteEntry()
            .pipe(take(1))
            .subscribe(note => {
                if (note) {
                    this.timeClockNote = note.note;
                    this.cdr.detectChanges();
                }
            });

    }

    calculateHours(data: TimeClockEntry) {
        let totalDurationMinutes: number = data?.timeClockAction === TimeClockAction.Driving
            ? data?.totalDurationInMinutesOverride
            : data?.totalDurationInMinutes;

        return ((totalDurationMinutes ?? 0) / 60).toFixed(2);
    }

    calculateTotalHours(data) {
        return `${(data.value / 60).toFixed(2)} Total Hours`;
    }

    closeModal() {
        this.activeModal.close();
    }

    ngOnDestroy() {
        super.onDestroy();
    }
}
