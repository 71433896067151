export class RouteConstants {
    // Pages Routes
    static PAGES_ROOT = 'pages';
    // Admin Routes
    static ADMIN_ROOT = 'admin';
    static ADMIN_OFFERINGS = 'offerings';
    static ADMIN_CHARGE_CODES_DASHBOARD = 'charge-codes/dashboard';
    // Admin Client
    static ADMIN_CLIENTS_DASHBOARD = 'client/dashboard';
    static CLIENT_ROOT = 'client';
    static CLIENT_CONTRACTS = 'contracts';
    static CLIENT_DASHBOARD = 'dashboard';
    static CLIENT_FACILITIES = 'facilities';
    static CLIENT_INTERNAL_ALERTS = 'internal-alerts';
    static CLIENT_RATES = 'rates';
    static CLIENT_CONTRACT_DEFAULTS = 'contract-defaults';
    static CLIENT_CPT_OVERRIDE = 'cpt-override';
    static CLIENT_PAYER_OVERRIDE = 'payer-override';

    // Admin Client User Admin
    static ADMIN_CLIENT_DASHBOARD = 'client-admin/dashboard';
    // Admin -> Clinical Excellence
    static ADMIN_CE = 'clinical-excellence';
    static ADMIN_CE_DEFICIT_STRATEGY = 'deficit-strategy';
    static ADMIN_CE_DEFICITS = 'deficits';
    static ADMIN_CE_GOAL_STATEMENTS = 'goal-statements';
    static ADMIN_CE_FUNCTIONAL_DAILY_NOTES = 'functional-daily-notes';
    static ADMIN_CE_INTERVENTIONS = 'interventions';
    static ADMIN_CE_STRATEGIES = 'strategies';
    
    // Admin Communcations
    static ADMIN_COMMUNICATIONS = 'communications';
    static ADMIN_COMMUNICATION_DASHBOARD = 'dashboard';
    static ADMIN_COMMUNICATION_MORNING_MEETING = 'morning-meetings';

    // Admin Facility
    static ADMIN_FACILITY_ROOT = 'facility';
    static ADMIN_FACILITY_DASHBOARD = 'dashboard';
    static ADMIN_FACILITY_EDIT = 'edit';
    static ADMIN_FACILITY_STAFF_ASSIGNMENTS = 'staff-assignments';
    // Admin Employee
    static ADMIN_EMPLOYEE_ROOT = 'employee';
    static ADMIN_EMPLOYEE_DASHBOARD = 'dashboard';
    static ADMIN_EMPLOYEE_CREATE = 'create'
    static ADMIN_EMPLOYEE_BASIC_INFO = 'basic-info';
    static ADMIN_EMPLOYEE_LICENSES = 'licenses';
    // Admin Export
    static ADMIN_EXPORT_ROOT = 'export';
    static ADMIN_EXPORT_RUNS = 'runs';
    static ADMIN_EXPORT_PROFILES = 'profiles';
    static ADMIN_EXPORT_SCHEDULES = 'schedules';
    // Admin Intermediary
    static ADMIN_INTERMEDIARY_DASHBOARD = 'intermediary/dashboard';
    // Admin Mass Unlock Charges
    static ADMIN_MASS_UNLOCK_DASHBOARD = 'mass-unlock-charges/dashboard';
    // Admin Master Payer
    static ADMIN_MASTER_PAYER_DASHBOARD = 'master-payer/dashboard';
    // Admin Operations
    static ADMIN_OPERATIONS_APPROVALS = 'approvals';
    static ADMIN_OPERATIONS_OVERVIEW = 'overview';
    // Admin Physician
    static ADMIN_PHYSICIAN_ROOT = 'physician';
    static ADMIN_PHYSICIAN_DASHBOARD = 'dashboard';
    static ADMIN_PHYSICIAN_CREATE = 'create'
    static ADMIN_PHYSICIAN_BASIC_INFO = 'basic-info';
    // Admin Report Scheduler
    static ADMIN_REPORT_SCHEDULER = 'report-scheduler/dashboard';
    // Admin Regions & Areas
    static ADMIN_REGION_AREA_DASHBOARD = 'regions/dashboard';
    // Admin Roles & Permissions
    static ADMIN_ROLES = 'roles';
    // Admin System Health
    static ADMIN_SYSTEM_HEALTH = 'system-health/dashboard';
    // Billing Routes
    static BILLING_HOME = 'home';
    static BILLING_INVOICES = 'invoices';
    static BILLING_INVOICE_ADMIN = 'invoice-admin';
    static BILLING_ASSIGNMENT = 'assignment';
    static BILLING_MODIFIED_CHARGES = 'modified-charges';
    // Charges routes
    static CHARGES_NONTREATMENT = 'nontreatment';
    static CHARGES_PATIENTS = 'patients';
    static CHARGES_SUMMARY = 'summary';
    static CHARGES_TREATMENTS = 'treatments';

    // Client Portal Routes
    static CLIENT_PORTAL_ROOT = 'client-portal';
    static CLIENT_PORTAL_DASHBOARD = 'dashboard';
    static CLIENT_PORTAL_EPISODES = 'episodes';
    static CLIENT_PORTAL_DOCUMENTS = 'documents';
    static CLIENT_PORTAL_DENIAL_DASHBOARD = 'denials';
    static CLIENT_PORTAL_DENIAL_HOME = 'home';
    static CLIENT_PORTAL_DENIAL_PREVIOUS_NOTIFICATION = 'previous-notification';

    // Clinical Excellence
    static CE_CASPER = 'casper';

    // Coding Integrity Routes
    static CODING_INTEGRITY = 'coding-integrity';
    static CODING_INTEGRITY_MDS = 'mds-upload';
    static CODING_INTEGRITY_PREMIER_CODING = 'premier-coding';
    
    // Denial Management Routes
    static DENIAL_MANAGEMENT_ROOT = 'denial-management';
    static DENIAL_MANAGEMENT_CLAIM_DETAILS = 'denial-claim';
    static DENIAL_MANAGEMENT_CLAIM_DETAILS_BASIC_INFO = 'basic-info';
    static DENIAL_MANAGEMENT_CLAIM_DETAILS_STAGES = 'stages';
    static DENIAL_MANAGEMENT_CLAIM_DETAILS_DOCS = 'documentation';
    static DENIAL_MANAGEMENT_CLAIM_DETAILS_NOTES = 'notes';
    static DENIAL_MANAGEMENT_CLAIM_DETAILS_POST_PAYMENT = 'post-payment';
    static DENIAL_MANAGEMENT_DASHBOARD = 'dashboard';
    static DENIAL_MANAGEMENT_HOME = 'active-denials';
    static DENIAL_MANAGEMENT_CLIENT = 'client-notifications';
    static DENIAL_MANGEMENT_PREAUTHORIZE = 'preauthorize';
    // Facility Routes
    static FACILITY_ROOT = 'facility';
    static FACILITY_DASHBOARD = 'dashboard';
    static FACILITY_BATCH_PRINTING = 'batch-printing';
    static FACILITY_CASELOAD = 'caseload';
    static FACILITY_CASPER = 'casper';
    static FACILITY_DOCUMENTS = 'documents';
    static FACILITY_ECHARTS = 'charts';
    static FACILITY_FUNCTION_BUILDERS = 'function-builders';
    static FACILITY_FUNCTION_BUILDERS_ACTIVE = 'active';
    static FACILITY_FUNCTION_BUILDERS_INACTIVE = 'inactive';
    static FACILITY_FUNCTION_BUILDERS_CHARGES = 'fb-charges';
    static FACILITY_FUNCTION_BUILDERS_CHARGES_TREATMENTS = 'treatments';
    static FACILITY_FUNCTION_BUILDERS_CHARGES_NONTREATMENTS = 'nontreatments';
    static FACILITY_HOME = 'home';
    static FACILITY_COST_PROJ = 'cost-projections';
    static FACILITY_NON_COVERED = 'non-covered';
    static FACILITY_NON_COVERED_COST_PROJ = 'non-covered-cost-proj';
    static FACILITY_RESIGN_ITEMS = 'resign';
    static FACILITY_RESTORATIVE_NURSING = 'restorative-nursing';
    static FACILITY_RESTORATIVE_NURSING_ACTIVE = 'active';
    static FACILITY_RESTORATIVE_NURSING_INACTIVE = 'inactive';
    static FACILITY_RESTORATIVE_NURSING_CHARGES = 'rnp-charges';
    static FACILITY_RESTORATIVE_NURSING_SCHEDULE = 'schedule';
    static FACILITY_RESTORATIVE_NURSING_TREATMENTS = 'treatments';
    static FACILITY_RESTORATIVE_NURSING_NONTREATMENTS = 'nontreatments';
    static FACILITY_SCREENS = 'screens';
    static FACILITY_SIGNATURES = 'signatures';
    static FACILITY_SCREENS_COMPLETED = 'completed';
    static FACILITY_UNLOCK_TREATMENTS = 'unlock-treatments';

    // Insurance Verification Routes
    static INSURANCE_VERIFICATION_ROOT = 'insurance-verification';
    static INSURANCE_VERIFICATION_DASHBOARD = 'dashboard';
    static INSURANCE_VERIFICATION_PREAUTH_APPROVALS = 'preauth';
    static INSURANCE_VERIFICATION_VERIFICATION = 'verification';
    static INSURANCE_VERIFICATION_PREAUTH_APPROVAL_REQUEST = 'request';
    
    // Integration Routes
    static INTEGRATION_ROOT = 'integration';
    static INTEGRATION_DASHBOARD = 'dashboard';
    static INTEGRATION_POINTS = 'points';
    static INTEGRATION_PROVIDERS = 'providers';
    // Home Route
    static HOME = 'home';
    static HOME_ANNOUNCEMENTS = 'announcements';
    static HOME_SUPPORT = 'support';
    static HOME_KNOWLEDGE = 'knowledgebase';
    static HOME_QUICK_LINKS = 'quicklinks';
    
    // Operation Routes
    static OPERATIONS_ROOT = 'operations';
    static OPERATIONS_OVERVIEW = 'overview';
    static OPERATIONS_APPROVALS = 'approvals';
    static OPERATIONS_DASHBOARD = 'dashboard';
    static OPERATIONS_SCANNED_FILES_DASHBOARD = 'scanned';
    static OPERATIONS_SCANNED_FILES = 'files';
    
    // Patient Routes
    static PATIENT_ROOT = 'patient';
    static PATIENT_CREATE = 'patient-create';
    static PATIENT_DASHBOARD = 'dashboard';
    static PATIENT_ADMIT_DATA = 'admit-data';
    static PATIENT_INTERVIEW = 'interview';
    static PATIENT_CLINIC_DOCUMENT = 'clinical-doc'
    static PATIENT_DOCUMENT = 'document';
    static PATIENT_EVAL = 'evaluation';
    static PATIENT_MED_DATA = 'medical-data';
    static PATIENT_RECERT = 'recert';
    static PATIENT_SEARCH = 'patient-search';

    // Patient Clinical Doc Routes
    static PAT_CLINICAL_DOC_EVAL_INFO = 'info';
    static PAT_CLINICAL_DOC_MODULES = 'modules';
    static PAT_CLINICAL_DOC_OTHER_NOTES = 'other-notes';
    static PAT_CLINICAL_DOC_OUTCOMES = 'outcomes';
    static PAT_CLINICAL_DOC_ST_OUTCOMES = 'stOutcomes';
    static PAT_CLINICAL_DOC_PLANNED_DC = 'planned-dc';
    static PAT_CLINICAL_DOC_COMPLEXITY = 'complexity';
    static PAT_CLINICAL_DOC_POC = 'plan-of-care';
    static PAT_CLINICAL_DOC_FINALIZE = 'finalize';
    static PAT_CLINICAL_DOC_RECERT_INFO = 'recert-info';
    static PAT_CLINICAL_DOC_EOP = 'extent-of-progress';
    static PAT_CLINICAL_DOC_DAILY_NOTES = 'daily-notes';
    static PAT_CLINICAL_DOC_DC_INFO = 'dc-info';
    static PAT_CLINCIAL_DOC_LEGACY = 'legacy';
    static PAT_CLINICAL_DOC_WEEKLY_UPDATE = 'weekly-update';

    // Practitioner Routes
    static PRACTITIONER_ROOT = 'practitioner';
    static PRACTITIONER_HOME = 'home';
    static PRACTITIONER_CASELOADS = 'caseloads';
    static PRACTITIONER_EPISODES = 'episodes';
    static PRACTITIONER_DOCUMENTS = 'documents';

    // Scheduling Routes
    static SCHEDULING_ROOT = 'schedule';
    static SCHEDULING_PATIENT = 'patient';
    static SCHEDULING_PATIENT_PLANNER = 'patient-planner';
    static SCHEDULING_THERAPIST = 'therapist';
    static SCHEDULING_THERAPIST_PLANNER = 'therapist-planner';
    static SCHEDULING_SECONDARY = 'secondary';
    static SCHEDULING_SECONDARY_PLANNER = 'secondary-planner';
    static SCHEDULING_REASSIGN = 'reassign';

    // Startups Routes
    static STARTUPS_ROOT = 'startups';
    static CLOSE_OUT = 'close-out';
    static CLOSE_OUTS = 'close-outs';
    static CURRENT_STARTUPS = 'current-startups';
    static STARTUP_DETAILS = 'details';
    static STARTUP_DETAILS_ADMIN = 'admin';
    static STARTUP_DETAILS_BILLING = 'billing';
    static STARTUP_DETAILS_CLIENT_INFO = 'client-info';
    static STARTUP_DETAILS_DISTRIBUTION = 'distribution';
    static STARTUP_DETAILS_DOCS = 'docs';
    static STARTUP_DETAILS_FACILITY = 'facility';
    static STARTUP_DETAILS_FACILITY_INFO = 'facility-info';
    static STARTUP_DETAILS_HR = 'hr';
    static STARTUP_DETAILS_IT = 'it';
    static STARTUP_DETAILS_SOFTWARE = 'software';
    static STARTUP_DETAILS_STAFFING = 'staffing';
    static STARTUP_DETAILS_STARTUP_INFO = 'startup-info';
}
