import { Component, Input, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CacheManService, DataListConstants, KnowledgeBaseService, ToastMessageService } from '@app/core';
import { KnowledgeBaseItem, DataListItem } from '@app/model';
import { DeepCopyUtils, fileUploadValidation } from '@app/utils';

@Component({
  selector: 'tms-knowledge-base-add-item',
  templateUrl: './knowledge-base-add-item.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnowledgeBaseAddItemComponent implements OnInit {
  @Input() kbItem: KnowledgeBaseItem = null;
  @ViewChild(DxFileUploaderComponent) fileuploader: DxFileUploaderComponent;

  departments$: Observable<DataListItem[]> = null;
  editMode: boolean = false;
  isVideo: boolean = false;
  kbItemToAdd: KnowledgeBaseItem = {};
  title: string = '';

  constructor(private activeModalService: NgbActiveModal,
    private cacheManService: CacheManService,
    private knowledgeBaseService: KnowledgeBaseService,
    private toastMessageService: ToastMessageService) { }

  ngOnInit() {
    this.departments$ = this.cacheManService.getDataListItems$(DataListConstants.SUPPORT_ISSUE_DEPARTMENT);

    if (this.kbItem) {
      this.kbItemToAdd = DeepCopyUtils.deepCopyObject(this.kbItem);
      this.editMode = true;
      if (this.kbItem.videoSrc) {
        this.isVideo = true;
      }
      this.title = 'Edit Knowledge Base Item';
    } else {
      this.editMode = false;
      this.title = 'Add Knowledge Base Item';
      this.kbItemToAdd.isActive = true;
    }

    if (!this.kbItemToAdd.keywords) {
      this.kbItemToAdd.keywords = [];
    }
    if (!this.kbItemToAdd.primaryKeywords) {
      this.kbItemToAdd.primaryKeywords = [];
    }
  }

  onCloseKnowledgeBaseItem() {
    this.activeModalService.dismiss();
  }

  onAddKnowledgeBaseItem(e) {
    const validationResult = e.validationGroup.validate();
    if (validationResult.isValid) {
      // edit mode
      if (this.kbItem) {
        this.knowledgeBaseService.updateKnowledgeBaseItem(this.kbItemToAdd)
          .subscribe(() => {
            this.activeModalService.close();
          }, error => this.toastMessageService.errorNotification(`Update failed. ${error.error}`))
      } else {
        if (this.isVideo) {
          this.knowledgeBaseService.addKnowledgeBaseItemVideo(this.kbItemToAdd)
            .subscribe(() => {
              this.activeModalService.close();
            }, error => this.toastMessageService.errorNotification(`Update failed. ${error.error}`))
        } else {
          this.knowledgeBaseService.addKnowledgeBaseItem(this.kbItemToAdd, this.fileuploader.value[0].name, this.fileuploader.value[0])
            .subscribe(() => {
              this.activeModalService.close();
            }, error => this.toastMessageService.errorNotification(`Update failed. ${error.error}`))
        }
      }
    }
  }

  onCustomItemCreatingPrimary(args) {
    let newValue = args.text;
    this.kbItemToAdd.primaryKeywords.push(newValue);
    args.customItem = newValue;
  }

  onCustomItemCreating(args) {
    let newValue = args.text;
    this.kbItemToAdd.keywords.push(newValue);
    args.customItem = newValue;
  }

  onIsVideoValueChanged(e) {
    if (!e.value) {
      this.kbItemToAdd.videoSrc = '';
    }
  }

  validateFile = (params) => {
    return fileUploadValidation(params, this.fileuploader.value);
  }
}
