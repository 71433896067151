import { TimeClockEntry } from "@app/model";
import * as moment from 'moment';

export class TimeClock {
    public static FilterUnapprovedPtoEntries(timeClockEntries: TimeClockEntry[]): TimeClockEntry[] {
        return timeClockEntries?.filter(entry => entry && (!entry.isPto || (entry.isPto && entry.approved))) ?? [];
    }

    public static FilterEntriesByToday(timeClockEntries: TimeClockEntry[]): TimeClockEntry[] {
        let startOfToday = moment().startOf('day');
        let endOfToday = moment().endOf('day');
        return timeClockEntries?.filter(entry => { 
            return entry && moment(entry.clockIn).isBetween(startOfToday, endOfToday, undefined, '[]')
        }) ?? [];
    }
}