import { NbMenuItem } from '@nebular/theme';
import { RouteConstants } from '@app/core';

export const DASHBOARD_MENU_INDEX = 0;

export const SCHEDULING_MENU_INDEX = 1;
export const SCHEDULING_MENU_CHILD_PAT_PLANNER = 0;
export const SCHEDULING_MENU_CHILD_THER_PLANNER = 1;
export const SCHEDULING_MENU_CHILD_SEC_PLANNER = 2;
export const SCHEDULING_MENU_REASSIGN = 3;

export const FACILITY_MENU_INDEX = 2;
export const FACILITY_DASHBOARD_CHILD_MENU_INDEX = 0;
export const FACILITY_LINK = '/pages/facility';
export const FACILITY_DASHBOARD_LINK = 'dashboard';
export const FACILITY_MENU_CENSUS_MENU_LINK = 'census';
export const FACILITY_MENU_CENSUS_MENU_INDEX = 1;
export const FACILITY_MENU_CHARGES_MENU_INDEX = 2;
export const FACILITY_MENU_CHARGES_LINK = '/pages/charges/dashboard/';
export const FACILITY_MENU_CHARGES_TITLE = 'Charges';
export const FACILITY_MENU_CHARTS_MENU_INDEX = 3;
export const FACILITY_MENU_CHARTS_LINK = 'charts';
export const FACILITY_MENU_CHARTS_TITLE = 'Electronic Charts';
export const FACILITY_MENU_BATCH_PRINTING_MENU_INDEX = 4;
export const FACILITY_MENU_CASE_ASSIGNMENTS_MENU_LINK = 'case-assignments';
export const FACILITY_MENU_CASE_ASSIGNMENTS_MENU_INDEX = 5;
export const FACILITY_MENU_NON_COVERED_COST_PROJ__MENU_INDEX = 6;
export const FACILITY_MENU_PATIENT_TRACKING_MENU_LINK = 'patient-tracking';
export const FACILITY_MENU_PATIENT_TRACKING_MENU_INDEX = 7;
export const FACILITY_MENU_RESTORATIVE_NURSING_MENU_INDEX = 8;
export const FACILITY_MENU_RESTORATIVE_NURSING_MENU_INDEX_DASHBOARD = 0;
export const FACILITY_MENU_RESTORATIVE_NURSING_MENU_INDEX_CHARGES = 1;
export const FACILITY_MENU_FUNCTION_BUILDERS_MENU_INDEX = 9;
export const FACILITY_MENU_FUNCTION_BUILDERS_MENU_INDEX_DASHBOARD = 0;
export const FACILITY_MENU_FUNCTION_BUILDERS_MENU_INDEX_CHARGES = 1;
export const FACILITY_MENU_SCREENS_LINK = 'screens';
export const FACILITY_MENU_SCREENS_TITLE = 'Screens';
export const FACILITY_MENU_SCREENS_MENU_INDEX = 10;
export const FACILITY_MENU_CASPER_MENU_INDEX = 11;
//export const FACILITY_MENU_WEEKLY_UPDATE_MENU_LINK = 'weekly-update';
//export const FACILITY_MENU_WEEKLY_UPDATE_MENU_INDEX = 9;

export const PATIENT_MENU_INDEX = 3;
export const PATIENT_DASHBOARD_CHILD_MENU_INDEX = 0;
export const PATIENT_DASHBOARD_LINK = '/pages/patient/dashboard/';

export const CLIENT_PORTAL_MENU_INDEX = 4;
export const CLIENT_PORTAL_MENU_DASHBOARD_INDEX = 0;
export const CLIENT_PORTAL_MENU_PATIENT_PLANNER_INDEX = 1;
export const PRACTITIONER_MENU_INDEX = 5;

export const REPORTS_MENU_INDEX = 7;
export const REPORTS_MENU_DASHBOARD_INDEX = 0;
export const REPORTS_MENU_POWERBI_INDEX = 1;

export const ADMIN_MENU_INDEX = 8;

export const ADMIN_BILLING_MENU_INDEX = 0;
export const ADMIN_BILLING_INTERMEDIARY_INDEX = 0;
export const ADMIN_BILLING_CPT_ADMIN_INDEX = 1;
export const ADMIN_BILLING_MASTER_PAYER_INDEX = 2;

export const ADMIN_CLIENTS_MENU_INDEX = 1;
export const ADMIN_CLIENTS_MENU_DASHBOARD_INDEX = 0;
export const ADMIN_CLIENTS_MENU_FACILITY_INDEX = 1;
export const ADMIN_CLIENTS_FEATURE_FLAGS_INDEX = 2;
export const ADMIN_CLIENTS_OFFERINGS_INDEX = 3;
export const ADMIN_CLIENTS_REGIONS_INDEX = 4;

export const ADMIN_COMMUNICATIONS_MENU_INDEX = 2;
export const ADMIN_MASS_UNLOCK_CHARGES_INDEX = 3;

export const ADMIN_REPORT_MENU_INDEX = 4;
export const ADMIN_REPORT_ITEMS_DASHBOARD = 0;
export const ADMIN_REPORT_SCHEDULER_INDEX = 1;

export const ADMIN_SYSTEM_HEALTH_INDEX = 5;

export const ADMIN_USER_MENU_INDEX = 6;
export const ADMIN_USER_EMPLOYEE_MENU_INDEX = 0;
export const ADMIN_USER_PRACTITIONER_MENU_INDEX = 1;
export const ADMIN_USER_CLIENT_MENU_INDEX = 2;
export const ADMIN_USER_ROLES_MENU_INDEX = 3;

export const BILLING_MENU_INDEX = 12;
export const BILLING_MENU_DASHBOARD_CHILD_MENU_INDEX = 0;
export const BILLING_MENU_EXPORT_CHILD_MENU_INDEX = 1;
export const BILLING_MENU_INVOICES_CHILD_MENU_INDEX = 2;

export const CODING_INTEGRITY_MENU_INDEX = 13;
export const CODING_INTEGRITY_DASHBOARD_CHILD_MENU_INDEX = 0;
export const CODING_INTEGRITY_MDS_UPLOAD_INDEX = 1;

export const DENIAL_MANAGEMENT_MENU_INDEX = 14;
export const COMPLIANCE_MENU_INDEX = 15;

export const DENIALS_CLIENT_MENU_INDEX = 16;

export const OPERATIONS_MENU_INDEX = 17;

export const STARTUPS_MENU_INDEX = 18;

export const INSURANCE_VERIFICATION_INDEX = 19;

export const INTEGRATION_MGMT_INDEX = 20;

export const HR_INDEX = 21;
export const HR_EMPLOYEE_ADMIN_CHILD_MENU_INDEX = 0;

export const CE_MENU_INDEX = 22;
export const CE_MENU_CASPER_INDEX = 0;
export const CE_MENU_DEFICIT_STRATEGY_INDEX = 1;
export const CE_MENU_GOAL_STATEMENTS_INDEX = 2;
export const CE_MENU_INTERVENTIONS_INDEX = 3;

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'fa-columns',
    link: '/pages/dashboard',
    hidden: true,
    home: false,
    children: [
      {
        title: 'Action Items',
        link: '/pages/dashboard/action-items',
      },
    ],
  },
  {
    title: 'Scheduling',
    icon: 'fa-calendar-alt',
    link: '/pages/schedule/',
    hidden: true,
    home: false,
    children: [
      {
        title: 'Patient Planner',
        link: '/pages/schedule/:id/patient/patient-planner',
      },
      {
        title: 'Therapist Planner',
        link: '/pages/schedule/:id/therapist',
        hidden: true,
      },
      {
        title: 'Ancillary Planner',
        link: '/pages/schedule/:id/secondary',
      },
      {
      title: 'Therapist Reassign',
      link: '/pages/schedule/:id/reassign',
    },
    ],
  },
  {
    title: 'Facility',
    icon: 'fa-clinic-medical',
    link: '/pages/facility/',
    hidden: true,
    children: [
      {
        title: 'Facility Dashboard',
        link: '/pages/facility/:id/dashboard',
        pathMatch: 'prefix',
      },
      {
        title: 'Census',
        link: '/pages/facility/:id/census',
        pathMatch: 'prefix',
      },
      {
        title: 'Charges',
        link: '/pages/charges/dashboard/:id',
        pathMatch: 'prefix',
      },
      {
        title: 'Electronic Charts',
        link: '/pages/facility/:id/charts',
        pathMatch: 'prefix',
      },
      {
        title: 'Batch Printing',
        link: `/pages/facility/:id/${RouteConstants.FACILITY_BATCH_PRINTING}`,
        pathMatch: 'prefix',
      },
      {
        title: 'Employee Case Assignments',
        link: '/pages/facility/:id/case-assignments',
        pathMatch: 'prefix',
      },
      {
        title: 'Non-Covered/Cost Projections',
        link: `/pages/facility/:id/${RouteConstants.FACILITY_NON_COVERED_COST_PROJ}`,
        pathMatch: 'prefix',
      },
      {
        title: 'Patient Tracking',
        link: '/pages/facility/:id/patient-tracking',
        pathMatch: 'prefix',
      },
      {
        title: 'Restorative Nursing',
        children: [
          {
            title: 'Dashboard',
            link: `/pages/facility/:id/${RouteConstants.FACILITY_RESTORATIVE_NURSING}`,
            pathMatch: 'prefix',
          },
          {
            title: 'Charges',
            link: `/pages/facility/:id/${RouteConstants.FACILITY_RESTORATIVE_NURSING_CHARGES}`,
            pathMatch: 'prefix',
          }
        ]
      },
      {
        title: 'Function Builders',
        children: [
          {
            title: 'Dashboard',
            link: `/pages/facility/:id/${RouteConstants.FACILITY_FUNCTION_BUILDERS}`,
            pathMatch: 'prefix',
          },
          {
            title: 'Charges',
            link: `/pages/facility/:id/${RouteConstants.FACILITY_FUNCTION_BUILDERS_CHARGES}`,
            pathMatch: 'prefix',
          }
        ]
      },
      {
        title: FACILITY_MENU_SCREENS_TITLE,
        link: '/pages/facility/:id/screens',
        pathMatch: 'prefix',
      },
      {
        title: 'CASPER Data Entry',
        link: '/pages/facility/:id/casper',
        pathMatch: 'prefix',
      },
      // {
      //   title: 'Weekly Update',
      //   link: '/pages/facility/:id/weekly-update',
      //   pathMatch: 'prefix',
      // },
    ],
  },
  {
    title: 'Patient Dashboard',
    icon: 'fa-user',
    link: '/pages/patient',
    home: false,
    pathMatch: 'prefix',
  },
  {
    title: 'Client Portal',
    icon: 'fa-user-md',
    pathMatch: 'full',
    hidden: true,
    children: [
      {
        title: 'Dashboard',
        link: '/pages/client-portal/dashboard'
      },
      {
        title: 'Patient Planner',
        link: '/pages/client-portal/patient-planner',
      }
    ] 
  },
  {
    title: 'Practitioner Portal',
    link: '/pages/practitioner/home',
    icon: 'fa-user-md',
    pathMatch: 'full',
    hidden: true,
  },
  {
    title: 'Documents',
    icon: 'ion-document',
    link: '/pages/document',
    home: false,
    hidden: true,
    children: [
      {
        title: 'Inpatient Evaluation',
        link: '/pages/document/evaluation/inpatient-evaluation',
      },
      {
        title: 'Outpatient Evaluation',
        link: '/pages/document/evaluation/outpatient-evaluation',
      },
    ],
  },
  {
    title: 'Reports',
    icon: 'fa-file-invoice',
    link: '/pages/dashboard',
    home: false,
    children: [
      {
        title: 'Dashboard',
        link: '/pages/report/dashboard',
      },
      {
        title: 'Power BI Reports',
        link: '/pages/report/power-bi',
      },
    ],
  },
  {
    title: 'Admin',
    icon: 'fa-calendar-check',
    link: '/pages/dashboard',
    home: false,
    hidden: true,
    children: [
      {
        title: 'Billing Admin',
        children: [
          {
            title: 'Intermediary',
            link: '/pages/admin/intermediary/dashboard',
          },
          {
            title: 'Charge Code Admin',
            link: '/pages/admin/charge-codes/dashboard/charge-code',
          },
          {
            title: 'Master Payers',
            link: '/pages/admin/master-payer/dashboard',
          },
          {
            title: 'ICD Code Edit',
            link: '/pages/admin/icd-code-edit',
          },
        ],
      },
      {
        title: 'Client Admin',
        children: [
          {
            title: 'Dashboard',
            link: '/pages/admin/client/dashboard',
          },
          {
            title: 'County Admin',
            link: '/pages/admin/county/dashboard',
          },
          {
            title: 'Facility Admin',
            link: '/pages/admin/facility/dashboard',
          },
          {
            title: 'Feature Flags Admin',
            link: '/pages/admin/feature-flags',
          },
          {
            title: 'Offerings',
            link: '/pages/admin/offerings',
          },
          {
            title: 'Regions & Areas',
            link: '/pages/admin/regions/dashboard',
            pathMatch: 'prefix',
          },
          {
            title: 'Travel Distances',
            link: '/pages/admin/travel-distances',
          },
        ],
      },
      {
        title: 'Communications',
        link: '/pages/admin/communications/dashboard',
        children: [
          {
            title: 'Dashboard',
            link: '/pages/admin/communications/dashboard',
          },
          {
            title: 'Morning Meetings',
            link: '/pages/admin/communications/morning-meetings',
          },
        ],
      },
      {
        title: 'Mass Unlock Charges',
        link: '/pages/admin/mass-unlock-charges/dashboard',
      },
      {
        title: 'Report Admin',
        children: [
          {
            title: 'Report Items Dashboard',
            link: '/pages/admin/report-items/dashboard',
          },
          {
            title: 'Report Scheduler',
            link: '/pages/admin/report-scheduler/dashboard',
          },
        ]
      },
      {
        title: 'System Health',
        link: '/pages/admin/system-health/dashboard',
        pathMatch: 'prefix',
      },
      {
        title: 'User Admin',
        children: [
          {
            title: 'Employee Admin',
            link: '/pages/admin/employee/dashboard',
          },
          {
            title: 'Practitioner Admin',
            link: '/pages/admin/physician/dashboard',
          },
          {
            title: 'Client User Admin',
            link: '/pages/admin/client-admin/dashboard',
          },
          {
            title: 'Roles & Permissions',
            link: '/pages/admin/roles',
          }
        ],
      },
    ],
  },
  {
    title: 'Payers',
    icon: 'ion-ios-calendar-outline',
    link: '/pages/payer',
    home: false,
    hidden: true,
    children: [
      {
        title: 'Master Payer',
        link: '/pages/payer/master-payer',
      },
    ],
  },
  {
    title: 'Payroll',
    icon: 'ion-ios-calendar-outline',
    link: '/pages/payroll',
    home: false,
    hidden: true,
    children: [
      {
        title: 'Holiday',
        link: '/pages/payroll/holiday',
      },
    ],
  },
  {
    title: 'Users',
    icon: 'ion-ios-calendar-outline',
    link: '/pages/user',
    home: false,
    hidden: true,
    children: [
      {
        title: 'List',
        link: '/pages/user/list',
      },
    ],
  },
  {
    title: 'Billing',
    icon: 'fa-file-invoice-dollar',
    home: false,
    hidden: true,
    children:[
      {
        title: 'Dashboard',
        link: '/pages/billing/dashboard',
        pathMatch: 'prefix',
      },
      {
        title: 'Exports',
        link: '/pages/billing/exports',
        pathMatch: 'prefix',
      },
      {
        title: 'Invoices',
        link: '/pages/billing/invoices',
        pathMatch: 'prefix',
      },
    ],
  },
  {
    title: 'Coding Integrity',
    icon: 'fa-keyboard',
    pathMatch: 'prefix',
    hidden: true,
    children: [
      {
        title: 'Dashboard',
        link: '/pages/coding-integrity/premier-coding',
      },
      {
        title: 'MDS Upload',
        link: '/pages/coding-integrity/mds-upload',
      },
    ]
  },
  {
    title: 'Denial Management',
    link: '/pages/denial-management/dashboard',
    icon: 'fa-file-alt',
    pathMatch: 'prefix',
    hidden: true,
  },
  {
    title: 'Compliance',
    link: '/pages/denial-management/audit-request',
    icon: 'fa-search',
    pathMatch: 'prefix',
    hidden: true,
  },
  {
    title: 'Denials',
    link: '/pages/client-portal/denials/home',
    icon: 'fa-file-alt',
    pathMatch: 'prefix',
    hidden: true,
  },
  {
    title: 'Operations',
    icon: 'fa-chart-area',
    pathMatch: 'prefix',
    hidden: true,
    children: [
      {
        title: 'Dashboard',
        link: '/pages/operations/dashboard',
        pathMatch: 'prefix'
      },
      {
        title: 'Scanned Files',
        link: '/pages/operations/scanned',
        pathMatch: 'prefix'
      }
    ],
  },
  {
    title: 'Startups',
    link: '/pages/startups',
    icon: 'fa-rocket',
    pathMatch: 'prefix',
    hidden: true,
  },
  {
    title: 'Insurance Verification',
    link: '/pages/insurance-verification',
    icon: 'fa-check-circle',
    pathMatch: 'prefix',
    hidden: true,
  },
  {
    title: 'Integration Management',
    link: '/pages/integration/',
    icon: 'fa-sync',
    pathMatch: 'prefix',
    hidden: true,
  },
  {
    title: 'Human Resources',
    icon: 'fa-users',
    hidden: true,
    children: [
      {
        title: 'Employees',
        link: '/pages/admin/employee/dashboard',
        pathMatch: 'prefix',
      },
    ],
  },
  {
    title: 'Clinical Excellence',
    icon: 'fa-notes-medical',
    hidden: true,
    children: [
      {
        title: 'CASPER National Averages',
        link: '/pages/clinical-excellence/casper',
        pathMatch: 'prefix',
      },
      {
        title: 'Deficit Strategy',
        link: '/pages/clinical-excellence/deficit-strategy',
        pathMatch: 'prefix',
      },
      {
        title: 'Goal Statements',
        link: '/pages/clinical-excellence/goal-statements',
        pathMatch: 'prefix',
      },
      {
        title: 'Interventions',
        link: '/pages/clinical-excellence/interventions',
        pathMatch: 'prefix',
      },
      {
        title: 'Functional Daily Notes',
        link: '/pages/clinical-excellence/functional-daily-notes',
        pathMatch: 'prefix',
      },
    ]
  },
  {
    title: 'Home',
    link: '/home',
    pathMatch: 'prefix',
    home: true,
    hidden: true,
  },
];
